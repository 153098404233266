// src/Components/Products/ProductDetails.tsx

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Typography,
  Button,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { CartItem, useCart } from "../../Context/CartContext";
import { toast } from "react-toastify";
import theme from "../../Context/theme";

export interface Price {
  id: string;
  amount: number;
  currency: string;
}

export interface Size {
  size: string;
}

export interface Product {
  id: string;
  title: string;
  sizes: Size[];
  prices: Price[];
  description: string;
  image: string;
  quantity?: number;
  priceValue?: number;
  currency?: string;
  selectedSize?: Size;
  isRaffle?: boolean;
}

interface ProductDetailsProps {
  product: Product;
  onClose: () => void;
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  product,
  onClose,
}) => {
  const [selectedSize, setSelectedSize] = useState<Size | null>(null);
  const [selectedPrice, setSelectedPrice] = useState<Price | null>(
    product.isRaffle ? product.prices[0] : null
  );
  const [openFullScreen, setOpenFullScreen] = useState(false);

  const { addToCart } = useCart();

  const handleSizeChange = (event: SelectChangeEvent<string>) => {
    const sizeValue = event.target.value as string;
    const sizeOption = product.sizes.find((s) => s.size === sizeValue);
    setSelectedSize(sizeOption || null);
    
    if (sizeOption) {
      // Assuming sizes and prices are aligned by index
      const index = product.sizes.indexOf(sizeOption);
      const correspondingPrice = product.prices[index];
      setSelectedPrice(correspondingPrice);
    } else {
      setSelectedPrice(null);
    }
  };

  const handleAddToCart = () => {
    if (!selectedPrice && !product.isRaffle) {
      toast.error("Please select a size.", {
        theme: "colored",
        style: {
          backgroundColor: theme.palette.error.main,
          color: "#fff",
        },
      });
      return;
    }

    const cartItem: CartItem = {
      title: product.title,
      image: product.image,
      quantity: 1,
      selectedSize: product.isRaffle ? undefined : selectedSize!,
      selectedPrice: selectedPrice!,
      isRaffle: product.isRaffle ?? false,
    };

    addToCart(cartItem);

    toast.success(
      product.isRaffle ? "Raffle entry added to cart!" : "Item added to cart!",
      {
        theme: "colored",
        style: {
          backgroundColor: theme.palette.rose.light,
          color: "#fff",
        },
      }
    );
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 300, letterSpacing: ".3rem" }}>
        {product.title}
      </DialogTitle>
      <DialogContent>
        <img
          src={product.image}
          alt={product.title}
          style={{ width: "100%" }}
          onClick={() => setOpenFullScreen(true)}
        />
        <Dialog fullScreen open={openFullScreen} onClose={() => setOpenFullScreen(false)}>
          <img
            src={product.image}
            alt={product.title}
            style={{ width: "100%", height: "100vh", objectFit: "contain" }}
          />
        </Dialog>

        <Typography variant="body2" sx={{ mt: 2 }}>
          {product.description}
        </Typography>

        {/* Raffle Logic */}
        {product.isRaffle ? (
          <Typography variant="h6" sx={{ mt: 2, fontWeight: 500 }}>
            Enter the raffle for just $
            {((selectedPrice?.amount || 0) / 100).toFixed(2)}!
          </Typography>
        ) : (
          // Size Selection Logic
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="size-select">Choose Size</InputLabel>
            <Select
              value={selectedSize?.size || ""}
              onChange={handleSizeChange}
            >
              {product.sizes.map((sizeOption, index) => (
                <MenuItem key={sizeOption.size} value={sizeOption.size}>
                  {sizeOption.size} - ${ (product.prices[index].amount / 100).toFixed(2) }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        <Button 
          onClick={handleAddToCart} 
          color="primary" 
          variant="contained" 
          disabled={!selectedPrice && !product.isRaffle}
        >
          {product.isRaffle ? "Enter Raffle" : "Add to Cart"}
        </Button>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
