// src/Components/Cart/Cart.tsx

import React, { useState } from "react";
import { CartItem, useCart } from "../../Context/CartContext";
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
  Box,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useStripe } from "@stripe/react-stripe-js";

const url = process.env.REACT_APP_CART_API_ROUTE;

if (!url) {
  throw new Error("REACT_APP_CART_API_ROUTE must be defined");
}

export const calculateTotal = (cart: CartItem[]): number => {
  return cart.reduce(
    (sum, item) => sum + item.selectedPrice.amount * item.quantity,
    0
  );
};

const formatCartForAPI = (cart: CartItem[]): any => {
  return {
    items: cart.map((item) => ({
      id: item.selectedPrice.id, // Live Stripe Price ID
      name: sanitizeName(item.title),
      size: item.isRaffle ? "" : item.selectedSize?.size || "",
      currency: item.selectedPrice.currency,
      quantity: item.quantity,
      price: item.selectedPrice.amount, // Amount in cents
      isRaffle: item.isRaffle || false,
    })),
  };
};

// Utility function to sanitize the product name
const sanitizeName = (name: string): string => {
  // Remove all double quotes
  return name.replace(/"/g, "");
};

// Subcomponent for individual cart items
const CartItemComponent: React.FC<{
  product: CartItem;
  onRemove: (id: string) => void;
  onIncrement: (id: string) => void;
  onDecrement: (id: string) => void;
}> = ({ product, onRemove, onIncrement, onDecrement }) => {
  return (
    <>
      <ListItem alignItems="flex-start" sx={{ paddingY: 2 }}>
        <ListItemAvatar>
          <Box
            component="img"
            src={product.image}
            alt={product.title}
            sx={{
              width: 100,
              height: 100,
              objectFit: "contain",
              borderRadius: 1,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {product.title}
            </Typography>
          }
          secondary={
            <Box sx={{ marginTop: 1 }}>
              {product.isRaffle ? (
                <Typography variant="body2" color="textSecondary">
                  Raffle Entry
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Size: {product.selectedSize?.size || "N/A"}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price: ${(product.selectedPrice.amount * product.quantity) / 100}
                  </Typography>
                </>
              )}
              <Typography variant="body2" color="textSecondary">
                Quantity: {product.quantity}
              </Typography>
            </Box>
          }
          sx={{ marginLeft: 2 }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <IconButton
            onClick={() => onIncrement(product.selectedPrice.id)}
            color="primary"
            size="small"
          >
            <AddIcon />
          </IconButton>
          <Typography variant="body1">{product.quantity}</Typography>
          <IconButton
            onClick={() => onDecrement(product.selectedPrice.id)}
            color="primary"
            size="small"
          >
            <RemoveIcon />
          </IconButton>
          <IconButton
            onClick={() => onRemove(product.selectedPrice.id)}
            color="error"
            aria-label="remove from cart"
            sx={{ marginTop: 1 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </ListItem>
      <Divider />
    </>
  );
};

export const Cart: React.FC = () => {
  const {
    cart,
    removeFromCart,
    clearCart,
    incrementQuantity,
    decrementQuantity,
  } = useCart();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const stripe = useStripe();

  const totalPrice = calculateTotal(cart);

  const initiateCheckout = async () => {
    setError(null);
    setLoading(true);

    try {
      // Validate cart items
      for (const item of cart) {
        if (
          !item.selectedPrice.id ||
          !item.selectedPrice.currency ||
          !item.selectedPrice.amount ||
          item.quantity < 1
        ) {
          throw new Error(`Invalid cart item: ${item.title}`);
        }
      }

      const requestBody = formatCartForAPI(cart);
      const jsonString = JSON.stringify(requestBody);
      console.log("Sending formatted cart to backend:", jsonString);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: jsonString,
      });

      console.log("Checkout API response status:", response.status);

      if (!response.ok) {
        const message = await response.text();
        console.error("Checkout API error message:", message);
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${message}`
        );
      }

      const data = await response.json();

      console.log("Checkout API response data:", data);

      if (data.sessionId && stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (result.error) {
          console.error(result.error);
          setError("Redirect to Stripe failed.");
        }
      } else if (data.error) {
        console.error("Backend error:", data.error);
        setError(data.error);
      } else {
        console.error("Stripe object is not ready or Session ID is missing.");
        setError("Stripe object is not ready or Session ID is missing.");
      }
    } catch (error: any) {
      console.error("Checkout initiation error:", error);
      setError(
        error.message || "An error occurred during the checkout process."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mt: 4,
        p: { xs: 2, md: 4 },
        boxShadow: 3,
        borderRadius: 2,
        maxWidth: 1000,
        margin: "auto",
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 600,
            letterSpacing: ".1rem",
            textAlign: "center",
            mb: 4,
            mt: 10
          }}
        >
          Your Shopping Cart
        </Typography>

        {cart.length > 0 ? (
          <>
            <List>
              {cart.map((product, index) => (
                <CartItemComponent
                  key={`${product.title}-${product.selectedPrice.id}-${index}`}
                  product={product}
                  onRemove={removeFromCart}
                  onIncrement={incrementQuantity}
                  onDecrement={decrementQuantity}
                />
              ))}
            </List>

            <Divider sx={{ my: 4 }} />

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Total: ${(totalPrice / 100).toFixed(2)}
                  </Typography>
                  <Button
                    onClick={clearCart}
                    color="secondary"
                    variant="outlined"
                    fullWidth
                  >
                    Clear Cart
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={initiateCheckout}
                    disabled={loading}
                    fullWidth
                  >
                    {loading ? "Processing..." : "Checkout"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              padding: 4,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              Your cart is currently empty.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              href="/shop" // Adjust the href to your shop page
            >
              Continue Shopping
            </Button>
          </Box>
        )}

        {/* Error Snackbar */}
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};
