import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const url = process.env.REACT_APP_CONTACT_API_ROUTE;

if (!url) {
  throw new Error("REACT_APP_CONTACT_API_ROUTE must be defined");
}

const Wizard = () => {
  const [size, setSize] = useState("");
  const [animal, setAnimal] = useState("");
  const [email, setemail] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [emailStatus, setEmailStatus] = useState<"idle" | "success" | "error">(
    "idle"
  );

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleAnimalChange = (event) => {
    setAnimal(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleEmailChange = (event) => {
    setemail(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const sendEmail = async () => {
    const emailContent = {
      name: name,
      email: email,
      size: size,
      animal: animal,
      description: description,
    };

    try {
      const response: Response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailContent),
      });

      if (response.status === 200) {
        setEmailStatus("success");
        // Clear the fields
        setSize("");
        setAnimal("");
        setDescription("");

        setTimeout(() => {
          setEmailStatus("idle");
        }, 5000);
      } else {
        setEmailStatus("error");
      }
    } catch (error: any) {
      setEmailStatus("error");
      console.error("Failed to send email:", error.message);
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        margin="dense"
        label="Enter your email"
        value={email}
        onChange={handleEmailChange}
      />

      <TextField
        fullWidth
        margin="dense"
        label="Enter Your Name"
        value={name}
        onChange={handleNameChange}
      />

      <FormControl fullWidth>
        <InputLabel id="size-label">Product Size</InputLabel>
        <Select labelId="size-label" value={size} onChange={handleSizeChange}>
          <MenuItem value={"5x7"}>5x7</MenuItem>
          <MenuItem value={"8x10"}>8x10</MenuItem>
          <MenuItem value={"11x14"}>11x14</MenuItem>
          <MenuItem value={"12x18"}>12x18</MenuItem>
          <MenuItem value={"24x36"}>24x36</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        margin="dense"
        label="Wildlife Species"
        value={animal}
        onChange={handleAnimalChange}
      />
      <TextField
        fullWidth
        margin="dense"
        label="Addtional Information"
        value={description}
        onChange={handleDescriptionChange}
      />
      <Button variant="contained" color="primary" onClick={sendEmail}>
        Send Email
      </Button>
      {emailStatus === "success" && <div>Email successfully sent</div>}
      {emailStatus === "error" && (
        <div>Error sending email. Please try again.</div>
      )}
    </div>
  );
};

export default Wizard;
