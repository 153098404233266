// src/Components/Products/ProductGallery.tsx

import React, { useEffect, useState } from "react";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import { Product } from "./ProductDetails";

interface GalleryProps {
  onSelectProduct: (product: Product) => void;
}

const url = process.env.REACT_APP_PRODUCT_API_ROUTE;

if (!url) {
  throw new Error("REACT_APP_PRODUCT_API_ROUTE must be defined");
}

export const ProductGallery: React.FC<GalleryProps> = ({ onSelectProduct }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP Error! Status: ${response.status}`);
        }

        // Parse the top-level response
        const responseBody = await response.json();

        // Check if 'body' exists and is a string
        let productsList;
        if (typeof responseBody.body === "string") {
          productsList = JSON.parse(responseBody.body);
        } else {
          productsList = responseBody;
        }

        // Ensure productsList is an array
        if (!Array.isArray(productsList)) {
          throw new Error("Expected an array of products from the API.");
        }

        console.log("Products from API:", productsList);

        const transformedData = transformStripeData(productsList);
        setProducts(transformedData);
      } catch (err) {
        if (err instanceof Error) {
          console.error("Error fetching products:", err.message);
          setError(err.message);
        } else {
          console.error("Unknown error:", err);
        }
      }
    };

    fetchProducts();
  }, [url]);

  return (
    <Grid container spacing={2}>
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {products.map((product) => (
        <Grid
          key={product.id}
          item
          xs={12}
          sm={6}
          md={4}
          onClick={() => onSelectProduct(product)}
        >
          <Card sx={{ cursor: "pointer" }}>
            <CardMedia
              component="img"
              height="auto"
              width="300"
              sx={{ objectFit: "contain" }}
              image={product.image}
              alt={product.title}
              loading="lazy"
            />
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 300, letterSpacing: ".3rem" }}>
                {product.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

function transformStripeData(stripeData: any[]): Product[] {
  return stripeData.map((product) => ({
    id: product.id,
    title: product.title,
    description: product.description || "",
    image: product.image || "default-image-url.jpg",
    prices: product.prices.map((price: any) => ({
      ...price,
      size: getSizeFromPrice(price.amount), // Map price to size
    })),
    sizes: product.prices.map((price: any) => ({
      size: getSizeFromPrice(price.amount),
    })),
    isRaffle: product.isRaffle || false,
  }));
}


function getSizeFromPrice(amount: number): string {
  // This is a basic example. Adjust based on your specific pricing.
  switch (amount) {
    case 2500:
      return "5x7";
    case 3500:
      return "8x10";
    case 5000:
      return "11x14";
    default:
      return "Unknown";
  }
}
