import React, { createContext, useContext, useEffect, useState } from "react";

interface CartContextType {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (productId: string) => void;
  clearCart: () => void;
  incrementQuantity: (productId: string) => void;
  decrementQuantity: (productId: string) => void;
}

export interface CartItem {
  title: string;
  image: string;
  selectedPrice: {
    id: string; // Live Price ID
    amount: number;
    currency: string;
  };
  selectedSize?: {
    size: string;
  };
  quantity: number;
  isRaffle: boolean;
}

interface CartProviderProps {
  children: React.ReactNode;
}

const updateCartInLocalStorage = (cart: CartItem[]) => {
  const cartJSON = JSON.stringify(cart);
  localStorage.setItem("userCart", cartJSON);
};

const getCartFromLocalStorage = (): CartItem[] => {
  const cartJSON = localStorage.getItem("userCart");
  return cartJSON ? JSON.parse(cartJSON) : [];
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cart, setCart] = useState<CartItem[]>(getCartFromLocalStorage());

  useEffect(() => {
    updateCartInLocalStorage(cart);
  }, [cart]);

  const addToCart = (item: CartItem) => {
    setCart((prev) => {
      const existingItem = prev.find(
        (p) =>
          p.title === item.title &&
          p.selectedPrice.id === item.selectedPrice.id &&
          (item.isRaffle || p.selectedSize?.size === item.selectedSize?.size)
      );
  
      if (existingItem) {
        // Increment quantity for the existing item
        return prev.map((p) =>
          p.selectedPrice.id === existingItem.selectedPrice.id
            ? { ...p, quantity: p.quantity + 1 }
            : p
        );
      } else {
        // Add the new item
        return [...prev, item];
      }
    });
  };
  

  const removeFromCart = (productId: string) => {
    setCart((prev) =>
      prev.filter((product) => product.selectedPrice.id !== productId)
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const incrementQuantity = (productId: string) => {
    setCart((prev) => {
      return prev.map((item) =>
        item.selectedPrice.id === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    });
  };

  const decrementQuantity = (productId: string) => {
    setCart((prev) => {
      return prev.map((item) =>
        item.selectedPrice.id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    });
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        clearCart,
        incrementQuantity,
        decrementQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must ve used with CartProvider");
  }
  return context;
};
